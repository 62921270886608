import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Slider from 'react-slick';
import SectionHeroTitleDown from '../../Components/PagesLeagues/SectionHeroTitleDown';
import SectionContent from '../../Components/PagesLeagues/SectionContent';
import SectionWho from '../../Components/PagesLeagues/SectionWho';
import SectionNeed from '../../Components/PagesLeagues/SectionNeed';
import SectionInfo from '../../Components/PagesLeagues/SectionInfo';
import SectionPartner from '../../Components/PagesLeagues/SectionPartner';
import SectionMorePartner from '../../Components/PagesLeagues/SectionMorePartner';
import Footer from '../../Components/Layout/Footer';
import LayoutStyles, { isMobile } from '../../Components/Layout/Styles/LayoutStyles';
import { Images } from '../../Themes';
import {
  HabitatIcon,
  PreventionIcon,
  SoinIcon,
  AidesJuridiquesIcon,
  CommunauteIcon,
} from '../../Themes/Icon';
import { SoutienBadgeIcon } from '../../Themes/IconBadge';

const useStyles = makeStyles(theme => ({
  mainLayout: {
    backgroundColor: '#ffffff',
    padding: 0,
    margin: 0,
  },
  layout: {
    ...LayoutStyles.fullWidthLayout,
    margin: '6rem 2rem',
    [theme.breakpoints.only('xs')]: {
      padding: '1rem',
    },
  },
  textOutil: {
    width: '70%',
    margin: 'auto',
    [theme.breakpoints.only('xs')]: {
      width: '100%',
    },
  },
}));

function Homepage() {
  const classes = useStyles();

  const settings = {
    arrows: !isMobile,
    className: 'center',
    centerMode: false,
    infinite: false,
    centerPadding: '90px',
    slidesToShow: 5,
    speed: 500,
    dots: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          dots: true,
        },
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
          dots: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Grid className={classes.mainLayout}>
      {/* HERO SECTION */}
      <SectionHeroTitleDown
        title1="DIAPASON 92"
        title2="Une alternative à la maison de retraite"
        subTitle="Dispositif Innovant d’Accompagnement des Personnes Âgées dans leur maiSON"
        backgroundImage={`linear-gradient(to bottom, rgba(255,255,255,0), rgba(255,255,255,0.9)), url(${Images.diapason.jumbo})`}
        title1Color1="#C25742"
        title1Color2="#C25742"
        title2Color1="#27789F"
        title2Color2="#27789F"
        subTitleColor="#27789F"
      />

      <Divider style={{ margin: '3rem', background: 'none' }} />

      <SectionWho
        title="Diapason 92"
        variantTitle="h3"
        bullet={[
          {
            bullet: ' ',
            title: 'Un service d’aide et de soins à Domicile',
            content:
              '<p>DIAPASON 92 est une alternative à l’EHPAD qui propose une palette de services coordonnés et adaptés pour répondre aux besoins des personnes accompagnées à leur domicile. C’est une approche domiciliaire avec une vision éthique, philosophique de l’accompagnement et respectueuse de la personne.</p><p><em>« La personne doit pouvoir formuler ses choix, ses désirs, se référer à son passé, à ses préférences, à ses habitudes de vie … »</em></p><p>Les professionnels intervenants s’efforcent de s’adapter à la personne et acceptent les règles qui prévalent dans leur chez soi : respect de leur rythme de vie, du rangement des affaires, aménagement du domicile, aides techniques...</p>',
          },
        ]}
        image={Images.diapason.ehpad_domicile}
      />

      <Divider style={{ margin: '3rem', background: 'none' }} />

      <SectionNeed
        title="Les objectifs du dispositif"
        rowItemSM={4}
        needs={[
          {
            icon: <HabitatIcon />,
            subTitle:
              'Permettre le maintien à domicile de personnes âgées en perte d’autonomie, dont l’état nécessiterait une prise en charge en EHPAD',
          },
          {
            icon: <PreventionIcon />,
            subTitle:
              'Évaluer de manière multidimensionnelle les besoins et les attentes des personnes en perte d’autonomie.',
          },
          {
            icon: <SoinIcon />,
            subTitle:
              'Proposer des prestations adaptées et des solutions technologiques innovantes pour un accompagnement personnalisé',
          },
          {
            icon: <AidesJuridiquesIcon />,
            subTitle: 'Proposer un dispositif à coût raisonnable et accessible',
          },
          {
            icon: <CommunauteIcon />,
            subTitle:
              'Coordonner et fédérer les professionnels et les prestataires présents ou à solliciter',
          },
          {
            icon: <SoutienBadgeIcon />,
            subTitle: 'Soutenir les proches aidants des personnes en perte d’autonomie',
          },
        ]}
        iconColor="#24759C"
      />

      <Divider style={{ margin: '5rem', background: 'none' }} />

      <SectionContent
        title="À qui s’adresse l’EHPAD à domicile ?"
        h3
        paragraph="<p>DIAPASON 92 s’adresse aux personnes âgées de plus de 60 ans, évaluées en GIR 1 à 4, résidant sur les communes d’Asnières-sur-Seine, Bois-Colombes, Courbevoie et La Garenne-Colombes, ne présentant pas de troubles sévères du comportement et dont la situation nécessiterait une entrée en EHPAD classique.</p><p>Le dispositif propose 30 places au total dont 15 places habilitées à une aide complémentaire.</p>"
        image={Images.diapason.personne_agee}
      />

      <Divider style={{ margin: '3rem', background: 'none' }} />

      <SectionWho
        title="Des équipes vous accompagnent"
        variantTitle="h3"
        bullet={[
          {
            bullet: ' ',
            title: 'Une référente-parcours',
            content:
              'La référente-parcours est l’interlocutrice unique pour le bénéficiaire, son entourage et les intervenants professionnels. Elle a pour rôle d’organiser, en lien avec le médecin coordonnateur de l’équipe, les prestations de soins et d’accompagnement.',
          },
          {
            bullet: ' ',
            title: 'Des professionnels et des partenaires',
            content:
              '<p>L’équipe de Diapason 92 est composée d’un médecin gériatre, d’un ergothérapeute, d’une assistante administrative et d’aide(s) soignant(s) chargés de la garde itinérante de nuit.</p><p>Elle travaille en étroite collaboration et de manière coordonnée avec les partenaires du secteur gériatrique ayant passé convention avec notre dispositif (Service d’aide à domicile, SSIAD, livraison repas…)</p>',
          },
        ]}
        image={Images.diapason.infographie}
      />

      <Divider style={{ margin: '5rem', background: 'none' }} />

      <Grid item className={classes.layout}>
        <Grid className={classes.textOutil}>
          <Typography variant="h3" align="center" gutterBottom>
            Des outils numériques innovants
          </Typography>
          <Typography variant="subtitle1" component="h3" align="center" gutterBottom>
            Pour le suivi et la coordination, le service DIAPASON dispose d’outils de suivi et de
            coordination innovants permettant d’échanger avec l’ensemble des membres de façon
            sécurisée
          </Typography>
        </Grid>
        <Grid style={{ margin: '80px 0' }}>
          <Slider {...settings}>
            <img src={Images.diapason.mobile3} alt="Connexion mobile" />
            <img src={Images.diapason.mobile4} alt="Connexion mobile" />
            <img src={Images.diapason.mobile6} alt="Connexion mobile" />
            <img src={Images.diapason.mobile7} alt="Connexion mobile" />
            <img src={Images.diapason.mobile8} alt="Connexion mobile" />
          </Slider>
        </Grid>
      </Grid>

      <Divider style={{ margin: '5rem', background: 'none' }} />

      <SectionInfo
        title="Contact & tarif"
        body="<p>Le tarif fera l’objet d’un devis des prestations. Il tient compte du plan d’aide, du GIR, et des ressources du bénéficiaire. Une aide complémentaire départementale peut-être sollicitée en fonction des situations. L’ensemble des prestations donnera lieu à une facturation unique mensuelle.</p>"
        subTitle="<p>Pour toute information ou demande d’admission, contactez Mme RIOS Doris, référente-parcours.</p><p>Tel : 01 40 86 42 37 / Mail : diapason92@fondation-aulagnier.fr</p>"
        buttonTitle="CARENOTEBOOK.HOMEPAGE.INFO.BUTTON"
        buttonUrl="mailto:diapason92@fondation-aulagnier.fr"
        backgroundColor="#24759C"
      />

      <Divider style={{ margin: '3rem', background: 'none' }} />

      <SectionPartner
        title="Qui sommes-nous ?"
        subTitle="Une initiative de DIAPASON 92 en partenariat avec WELLO"
        rowItemSM={6}
        partners={[
          {
            image: Images.diapason.partner,
            titlePartner: 'Service d’aide et de soins à Domicile',
            contentPartner:
              'DIAPASON 92 est porté par la Fondation Aulagnier, Établissement public autonome adhérent à la Fédération Hospitalière de France, membre du GCSMS PASAPAH.',
          },
          {
            image: Images.wello.partner,
            titlePartner: 'WELLO.HOMEPAGE.PARTNER.PARTNER_WELLO.TITLE2',
            contentPartner: 'WELLO.HOMEPAGE.PARTNER.PARTNER_WELLO.CONTENT',
            linkUrl: 'https://www.wello.fr/',
          },
        ]}
      />

      <Divider style={{ margin: '2rem', background: 'none' }} />

      <SectionMorePartner
        title="Nos partenaires"
        rowItemSM={3}
        partners={[
          {
            image: Images.diapason.aulagnier,
            titlePartner: 'Fondation Aulagnier',
          },
          {
            image: Images.diapason.gcsms,
            titlePartner: 'GCSMS Pasapah',
          },
          {
            image: Images.diapason.departement,
            titlePartner: 'Département Hauts-de-Seine',
          },
          {
            image: Images.diapason.ars,
            titlePartner: 'ARS',
          },
        ]}
      />

      <Divider style={{ margin: '2rem', background: 'none' }} />
      {/* FOOTER SECTION */}
      <Footer />
    </Grid>
  );
}

export default Homepage;
